var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dates && _vm.dates.length > 0)?_c('div',_vm._l((_vm.dates),function(item,indexDate){return _c('v-row',{key:indexDate},[_c('v-row',{staticClass:"row-baby"},[_c('h4',[_vm._v("Nascidos em "+_vm._s(item))])]),_c('v-row',[_vm._l((_vm.photos.filter(
          function (baby) { return baby.birthday === item; }
        )),function(baby,indexBaby){return _c('v-col',{key:indexBaby,attrs:{"cols":"6","sm":"3"}},[(baby.birthday === item)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('div',[_c('v-card',{attrs:{"elevation":hover ? 12 : 0}},[_c('v-img',{staticClass:"image",attrs:{"width":"100%","max-width":"300px","src":_vm.base_url + baby.src}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text",class:baby.genero === 'menino' ? 'menino' : 'menina',staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","fab":""},on:{"click":function($event){_vm.openModal(
                          _vm.photos.filter(function (baby) { return baby.birthday === item; }),
                          indexBaby
                        )}}},[_c('v-icon',{attrs:{"color":baby.genero === 'menino' ? 'blue' : 'pink'}},[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"small":"","fab":"","to":'fotos/' + baby.url_pag}},[_c('v-icon',{attrs:{"color":baby.genero === 'menino' ? 'blue' : 'pink'}},[_vm._v(" "+_vm._s(baby.genero === "menino" ? "mdi-gender-male" : "mdi-gender-female")+" ")])],1)],1):_vm._e()])],1)],1),_c('v-card-subtitle',{staticClass:"py-1 d-flex justify-center"},[_vm._v(_vm._s(baby.title))])],1)]}}],null,true)}):_vm._e()],1)}),_c('TinyBox',{attrs:{"loop":true,"images":_vm.imagesBox},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}})],2)],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }