<template>
  <div v-if="dates && dates.length > 0">
    <v-row v-for="(item, indexDate) in dates" :key="indexDate">
      <v-row class="row-baby">
        <h4>Nascidos em {{ item }}</h4>
      </v-row>
      <v-row>
        <v-col
          v-for="(baby, indexBaby) in photos.filter(
            (baby) => baby.birthday === item
          )"
          :key="indexBaby"
          cols="6"
          sm="3"
        >
          <v-hover v-if="baby.birthday === item" v-slot:default="{ hover }">
            <div>
              <v-card :elevation="hover ? 12 : 0">
                <v-img
                  width="100%"
                  max-width="300px"
                  class="image"
                  :src="base_url + baby.src"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text"
                      style="height: 100%"
                      :class="baby.genero === 'menino' ? 'menino' : 'menina'"
                    >
                      <v-btn
                        class="ma-2"
                        small
                        fab
                        @click="
                          openModal(
                            photos.filter((baby) => baby.birthday === item),
                            indexBaby
                          )
                        "
                      >
                        <v-icon
                          :color="baby.genero === 'menino' ? 'blue' : 'pink'"
                          >mdi-eye</v-icon
                        >
                      </v-btn>
                      <v-btn small fab :to="'fotos/' + baby.url_pag">
                        <v-icon
                          :color="baby.genero === 'menino' ? 'blue' : 'pink'"
                        >
                          {{
                            baby.genero === "menino"
                              ? "mdi-gender-male"
                              : "mdi-gender-female"
                          }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-card>
              <v-card-subtitle class="py-1 d-flex justify-center">{{
                baby.title
              }}</v-card-subtitle>
            </div>
          </v-hover>
        </v-col>

        <TinyBox :loop="true" v-model="index" :images="imagesBox" />
      </v-row>
    </v-row>
  </div>
</template>

<script>
import TinyBox from "vue-tinybox";

export default {
  components: {
    // VueGallerySlideshow,
    TinyBox,
    // CardPhotos,
  },
  computed: {
    photos() {
      return this.$store.state.photosBornH2P;
    },
    base_url() {
      return this.$store.state.base_url;
    },
    dates() {
      const datesArray = [];
      if (this.photos && this.photos.length > 0) {
        this.photos.forEach((element) => {
          if (datesArray.indexOf(element.birthday) < 0) {
            datesArray.push(element.birthday);
          }
        });
      }
      return datesArray;
    },
  },
  data: () => ({
    classGender: "",
    index: null,
    active: 0,
    viewed: false,
    itemsModal: {},
    imagesBox: [],
  }),
  methods: {
    openModal(data = null, i) {
      if (data) {
        this.imagesBox = [];
        data.forEach((element) => {
          let newSrc;
          newSrc = this.base_url + element.src;
          this.imagesBox.push(newSrc);
        });
        this.index = i;
      }
    },
  },
};
</script>

<style>
.title-modal {
  /* background-color: white; */
  overflow: hidden;
}

.menino {
  background-color: rgba(14, 168, 168, 0.671);
}

.menina {
  background-color: rgba(202, 96, 179, 0.493);
}

.v-card--reveal {
  height: 100%;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.row-baby {
  background-color: rgb(14, 129, 48);
  color: white;
  margin: 0px;
  padding: 10px;
  width: 100%;
}
</style>